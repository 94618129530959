<template>
  <div class="home" style="height: auto">
    <el-container style="height:100%">
      <el-header>
        <header-layout></header-layout>
      </el-header>

      <el-main style="width: 100%; margin: 0 auto">
        <div style="text-align: left; margin-left: 10%; margin-top: 0.5rem;">
          <h1 class="title" @click="()=>this.reload()">ESG指数</h1>
          <el-tooltip content="点击查看使用说明" effect="dark" placement="top">
            <i class="el-icon-question" style="color: #409EFF; margin-left: 2px;"
               @click="() => this.$refs.drawer2.visible = true"/>
          </el-tooltip>
        </div>
        <el-row :gutter="0" style="width: 80%; margin: 0 auto">
          <el-col :span=24>
            <div style="text-align: left; padding-right: 2rem">
              <h3 style="margin: 0; padding-top: 1rem">指数简介</h3>
              <p>
                本产品搜集整合了网络上来源广泛的碎片化信息，深度挖掘解读了文本背后隐含的信息，构建了一个集深度、广度于一体的领先市场的ESG评价体系，并以此为基础构造了ESG指数。
              </p>
              <p>
                我们选取CSR报告、公司新闻、上市公司调研问答报告和分析师报告作为多源文本，并进行指标在截面上和时序上的扩充后，我们将所有的指标区分成四种文本的E、 S、
                G三个领域共12个类别，每个类别内的指标标准化之后求和，得到该类别的评分。将这12项评分进行加权平均，得到我们最终的文本ESG总评分。
              </p>
              <p>
                参考沪深300指数编制方案， 我们根据文本ESG总评分排名，选择股票池中总评分前10%的股票作为我们指数的成分股，在每年的1月初和7月初进行调仓
                ，构建我们的文本ESG指数，为市场提供从文本信息来看优秀的公司股票的整体走向参考 。
              </p>
            </div>
          </el-col>

          <!--          <el-col :span=colWidths[0]>-->
          <!--            <div style="text-align: left">-->
          <!--              <h3 style="margin: 0; padding-top: 1rem">编制方案</h3>-->

          <!--            </div>-->

          <!--          </el-col>-->
        </el-row>

        <el-drawer
          ref="drawer1"
          :before-close="handleDrawerClose"
          :with-header="false"
          direction="rtl"
          size="40%"
          title="我是标题"
        >
          <iframe
            ref="drawer1iframe"
            onload="this.style.height= (window.innerHeight - 90) +'px';"
            src="https://doc.textualesg.com"
            style="width: 100%; border: hidden"
            title="Document of TextualESG"
          >
          </iframe>
        </el-drawer>

        <el-drawer
          ref="drawer2"
          :before-close="handleDrawerClose"
          :with-header="false"
          direction="rtl"
          size="40%"
          title="我是标题"
        >
          <iframe
            onload="this.style.height= (window.innerHeight - 90) +'px';"
            src="https://doc.textualesg.com/guide/userguide.html#%E6%8C%87%E6%95%B0"
            style="width: 100%; border: hidden"
            title="使用说明-ESG指数"
          >
          </iframe>
        </el-drawer>

        <div style="text-align: left; width: 80%; margin: 0 auto;">
          <h3 style="margin-top: 0rem; margin-bottom: 0.5rem">指数详情</h3>
        </div>
        <el-row :gutter="20" style="width: 80%; margin: 0 auto; background-color: #ffffff">
          <el-col :span=colWidths[2]>

            <div style="text-align: left; padding: 0.5rem">
              <el-date-picker
                v-model="startDate"
                placeholder="选择开始日期"
                style="margin-left: 0.8rem"
                type="month"
                value-format="yyyy-MM-dd"
                @change="getAllIndex">
              </el-date-picker>
              <i class="el-icon-right" style="margin-right: 0.5rem; margin-left: 0.5rem"></i>
              <el-date-picker
                v-model="endDate"
                placeholder="选择结束日期"
                type="month"
                value-format="yyyy-MM-dd"
                @change="getAllIndex">
              </el-date-picker>
            </div>
            <div id="chartIndex" ref="chartIndex" style="width: 100%"></div>
          </el-col>

          <el-col :span=colWidths[3]>
            <div style="text-align: left; margin-top: 0.8rem">
              <el-descriptions :column="6" direction="horizontal"
                               style="margin: 0 auto; font-size: larger; padding-top: 0.8rem; ">
                <el-descriptions-item span="6" label="最新日期">
                  <!--                  <el-date-picker-->
                  <!--                    v-model="updateTime"-->
                  <!--                    readonly-->
                  <!--                    placeholder="最近更新"-->
                  <!--                    style="margin-left: 0.8rem"-->
                  <!--                    type="date"-->
                  <!--                    @change="getAllIndex">-->
                  <!--                  </el-date-picker>-->
                  {{ updateTime }}
                </el-descriptions-item>
                <el-descriptions-item span="6" label="最早日期">
                  2015-02-01
                </el-descriptions-item>
              </el-descriptions>
              <h3>最新技术指标</h3>
              <el-descriptions :column="6" direction="horizontal"
                               style="margin: 0 auto; font-size: larger; padding-top: 0.8rem; ">
                <el-descriptions-item span="6" label="月收益率">
                  <!--                  <el-input v-model="indexPointer.pointer1" readonly></el-input>-->
                  <span style="color: #F56C6C; font-weight: bold"
                        v-if="indexPointer.pointer1 >= 0">&nbsp;{{ indexPointer.pointer1 * 100 }}%</span>
                  <span style="color: #67C23A; font-weight: bold"
                        v-if="indexPointer.pointer1 < 0">{{ indexPointer.pointer1 * 100 }}%</span>
                </el-descriptions-item>
                <el-descriptions-item span="6" label="季收益率">
                  <!--                  <el-input v-model="indexPointer.pointer2" readonly></el-input>-->
                  <span style="color: #F56C6C; font-weight: bold"
                        v-if="indexPointer.pointer2 >= 0">&nbsp;{{ indexPointer.pointer2 * 100 }}%</span>
                  <span style="color: #67C23A; font-weight: bold"
                        v-if="indexPointer.pointer2 < 0">{{ indexPointer.pointer2 * 100 }}%</span>
                </el-descriptions-item>
                <el-descriptions-item span="6" label="年收益率">
                  <!--                  <el-input v-model="indexPointer.pointer3" readonly>{{indexPointer.pointer3}}</el-input>-->
                  <span style="color: #F56C6C; font-weight: bold"
                        v-if="indexPointer.pointer3 >= 0">&nbsp;{{ indexPointer.pointer3 * 100 }}%</span>
                  <span style="color: #67C23A; font-weight: bold"
                        v-if="indexPointer.pointer3 < 0">{{ indexPointer.pointer3 * 100 }}%</span>
                </el-descriptions-item>
                <el-descriptions-item span="6" label="最大回撤">
                  <!--                  <el-input v-model="indexPointer.pointer4" readonly></el-input>-->
                  <span>&nbsp;{{ indexPointer.pointer4 * 100 }}%</span>
                </el-descriptions-item>
              </el-descriptions>
            </div>

          </el-col>
        </el-row>

        <div style="text-align: left; width: 80%; margin: 0 auto;">
          <h3 style="margin-top: 1rem; margin-bottom: 1rem">最新前十重仓股</h3>
        </div>

        <el-row :gutter="20" style="width: 80%; margin: 0 auto; padding-top: 1rem; background-color: #ffffff">
          <el-col :span=colWidths[4]>
            <div class="weight-table" style="margin-left: 1rem">
              <el-table
                ref="multipleTable"
                :data="weight"
                highlight-current-row
                row-key="name"
                stripe
                style="width: 100%;"
                @cell-click="handleCompanyClick"
              >
                <el-table-column
                  align="center"
                  label="证券代码"
                  min-width="100"
                  prop="code"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  label="证券名称"
                  min-width="100"
                  prop="name"
                >
                  <template v-slot="scope">
                    <el-tooltip content="点击查看公司详情" effect="dark" placement="right">
                      <el-link type="primary">{{ scope.row.name }}<i class="el-icon-view el-icon--right"></i></el-link>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="行业分类"
                  min-width="100"
                  prop="industry"
                >
                  <template v-slot="scope">
                    <span>
                    <i
                      :class="`el-icon--right ${icon_class[Object.values(industryNames).indexOf(scope.row.industry)]}`"></i>
                    {{ scope.row.industry }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="权重"
                  min-width="100"
                  prop="weight"
                >
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span=colWidths[5] style="text-align: center; overflow: scroll;">
            <div id="chartsMarket" ref="chartsMarket"></div>
            <div id="chartsIndustry" ref="chartsIndustry"></div>
          </el-col>
        </el-row>

        <div style="padding: 0.5rem;"></div>

      </el-main>

    </el-container>
    <FooterLayout></FooterLayout>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import HeaderLayout from '../../components/HeaderLayout.vue'
import {getIndex, getIndustryRatio, getMarketRatio, getWeight} from '@/api/indexinfo'
import * as echarts from 'echarts'
import FooterLayoutSlim from '../../components/FooterLayoutSlim'
import FooterLayout from '../../components/FooterLayout'
import {formatYYYYMM, getDownNum, getUpNum, latestMonth, latestYear} from '@/utils'

export default {
  name: 'esgindex',
  inject: ['reload'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FooterLayoutSlim,
    HeaderLayout,
    FooterLayout
  },
  data() {
    return {
      chartIndex: null,
      time: [],
      index: [],
      weight: [],
      indexPointer: {
        pointer1: 0.0,
        pointer2: 0.0,
        pointer3: 0.0,
        pointer4: 0.0
      },
      startDate: null,
      updateTime: '1970-01-01',
      endDate: null,
      marketRatio: {
        sh: 50,
        sz: 50
      },
      industryRatio: [],
      industryNames: {
        agriculture: '农林牧渔',
        chemicals: '基础化工',
        steel: '钢铁',
        nonferrous_metal: '有色金属',
        electronics: '电子',
        cars: '汽车',
        household_ele: '家用电器',
        foods: '食品饮料',
        clothes: '纺织服饰',
        light_m: '轻工制造',
        medicine: '医药生物',
        utilities: '公用事业',
        transportation: '交通运输',
        real_estate: '房地产',
        commercial_retail: '商贸零售',
        social_service: '社会服务',
        banking: '银行',
        nobank_finance: '非银金融',
        comprehensive: '综合',
        building_mat: '建筑材料',
        building_dec: '建筑装饰',
        power: '电力设备',
        machanical: '机械设备',
        defense: '国防军工',
        cs: '计算机',
        media: '传媒',
        communication: '通信',
        coal: '煤炭',
        petroleum: '石油石化',
        e_protection: '环保',
        beauty_care: '美容护理'
      },
      industryNames2: {
        agriculture: '农林牧渔',
        chemicals: '基础化工',
        steel: '钢铁',
        nonferrousMetal: '有色金属',
        electronics: '电子',
        cars: '汽车',
        householdEle: '家用电器',
        foods: '食品饮料',
        clothes: '纺织服饰',
        lightM: '轻工制造',
        medicine: '医药生物',
        utilities: '公用事业',
        transportation: '交通运输',
        realEstate: '房地产',
        commercialRetail: '商贸零售',
        socialService: '社会服务',
        banking: '银行',
        nobankFinance: '非银金融',
        comprehensive: '综合',
        buildingMat: '建筑材料',
        buildingDec: '建筑装饰',
        power: '电力设备',
        machanical: '机械设备',
        defense: '国防军工',
        cs: '计算机',
        media: '传媒',
        communication: '通信',
        coal: '煤炭',
        petroleum: '石油石化',
        eprotection: '环保',
        beautyCare: '美容护理'
      },
      icon_class: ['el-icon-grape', 'el-icon-magic-stick', 'el-icon-attract', 'el-icon-medal', 'el-icon-cpu', 'el-icon-truck',
        'el-icon-refrigerator', 'el-icon-ice-drink', 'el-icon-watch-1', 'el-icon-toilet-paper', 'el-icon-first-aid-kit',
        'el-icon-help', 'el-icon-box', 'el-icon-office-building', 'el-icon-goods', 'el-icon-service', 'el-icon-bank-card',
        'el-icon-coin', 'el-icon-connection', 'el-icon-brush', 'el-icon-scissors', 'el-icon-s-opportunity', 'el-icon-setting',
        'el-icon-aim', 'el-icon-monitor', 'el-icon-mic', 'el-icon-phone-outline', 'el-icon-discover', 'el-icon-odometer',
        'el-icon-sunny', 'el-icon-star-off'],
      colWidths: [8, 6, 18, 6, 12, 12],
    }
  },
  methods: {
    formatNum(row, column, cellValue) {
      let num = cellValue
      num = parseFloat(num)
      num = Math.round(num * Math.pow(10, 4)) / Math.pow(10, 4)
      num = Number(num).toFixed(4)
      return num + ''
    },

    formatNum2(num, dights) {
      num = parseFloat(num)
      num = Math.round(num * Math.pow(10, dights)) / Math.pow(10, dights)
      num = Number(num).toFixed(dights)
      return num + ''
    },

    compare(p) {
      return function (m, n) {
        const a = m[p]
        const b = n[p]
        return b - a
      }
    },

    handleCompanyClick(row, column, cell, event) {
      if (column.label === '证券名称') {
        if (this.$store.state.isLogin) {
          // 跳转到对应公司
          this.$router.push({
            path: '/rank/company',
            query: {
              code: row.code,
              year: latestYear,
              month: latestMonth
            }
          })
        } else {
          this.$message.info('登录后查看更多信息哦')
        }
      }
    },

    getAllIndex() {
      const that = this
      if (this.startDate && this.endDate) {
        if (this.startDate > this.endDate) {
          this.$message.warning('起始日期不能早于截止日期！')
          return
        }
      }
      getIndex().then(res => {
        res.data.forEach(oneIndex => {
          this.series[oneIndex.yyyyMm] = oneIndex.indexValue;
        })
        this.time = []
        this.values = []
        let prev = null
        const startDate = this.startDate ? this.startDate : null
        const endDate = this.endDate ? this.endDate : null

        console.log(startDate)
        console.log(endDate)

        for (const date in this.series) {
          const dateList = date.split('-')
          if ((startDate === null || date >= startDate) && (endDate === null || date <= endDate)) {
            if (dateList[1] !== prev) {
              this.time.push(date)
              this.values.push(this.series[date])
              prev = dateList[1]
            }
          }
        }
        // console.log(this.time)
        // console.log(this.values)
        this.drawIndexChart()
      })
    },

    getAllData() {
      getIndex().then(res => {
        this.series = []
        res.data.forEach(oneIndex => {
          this.series[oneIndex.yyyyMm] = oneIndex.indexValue
          if (oneIndex.yyyyMm > this.updateTime) {
            this.updateTime = oneIndex.yyyyMm
          }
        })
        this.time = []
        this.values = []
        let prev = null
        const startDate = this.startDate ? formatYYYYMM(this.startDate) : null
        const endDate = this.endDate ? formatYYYYMM(this.endDate) : null
        let maxDrawback = 0

        for (const date in this.series) {
          const dateList = date.split('-')
          if ((startDate === null || date >= startDate) && (endDate === null || date <= endDate)) {
            if (dateList[1] !== prev) {
              this.time.push(date)
              if (this.values[this.values.length - 1] - this.series[date] > maxDrawback) {
                maxDrawback = this.values[this.values.length - 1] - this.series[date]
              }
              this.values.push(this.series[date])
              prev = dateList[1]
            }
          }
        }


        this.startDate = this.time[0]
        this.endDate = this.updateTime
        console.log(this.endDate)
        this.indexPointer.pointer4 = maxDrawback
        this.indexPointer.pointer4 = this.formatNum2(this.indexPointer.pointer4 / 1000, 4)
        this.calcPointer()
        this.drawIndexChart()
      })
      const tmp = []
      getWeight().then(res => {
        for (let i = 0; i < 10; i++) {
          tmp.push({
            code: res.data[i].code,
            name: res.data[i].name,
            industry: this.industryNames[res.data[i].industry],
            weight: res.data[i].weight
          })
        }
        tmp.sort(this.compare('weight'))
        for (let i = 0; i < 10; i++) {
          tmp[i].weight = (tmp[i].weight * 100).toFixed(2) + "%"
        }
        console.log(tmp)
        this.weight = tmp
      })
      getMarketRatio().then(res => {
        this.marketRatio.sh = res.data.shP
        this.marketRatio.sz = res.data.szP
        this.drawMarketChart()
      })
      getIndustryRatio().then(res => {
        let sum = 0
        for (var key in res.data) {
          sum += res.data[key]
        }
        for (key in res.data) {
          this.industryRatio.push({
            name: this.industryNames2[key],
            value: res.data[key] / sum
          })
        }
        this.drawInIndustryChart()
      })
    },

    calcPointer() {
      const len = this.values.length
      this.indexPointer.pointer1 = this.values[len - 1] / this.values[len - 2] - 1
      this.indexPointer.pointer1 = this.formatNum2(this.indexPointer.pointer1, 4)
      this.indexPointer.pointer2 = this.values[len - 1] / this.values[len - 4] - 1
      this.indexPointer.pointer2 = this.formatNum2(this.indexPointer.pointer2, 4)
      this.indexPointer.pointer3 = this.values[len - 1] / this.values[len - 13] - 1
      this.indexPointer.pointer3 = this.formatNum2(this.indexPointer.pointer3, 4)
    },

    drawIndexChart() {
      if (this.chartMain != null) {
        this.chartMain.dispose()
      }
      this.chartIndex = echarts.init(this.$refs.chartIndex)
      const maxIndex = getUpNum(Math.ceil(Math.max.apply(null, this.values)), 100)
      let minIndex = getDownNum(Math.floor(Math.min.apply(null, this.values)), 100)
      const intervalIndex = Math.round((maxIndex - minIndex) / 5)
      while (minIndex >= 1000) minIndex = minIndex - intervalIndex
      const optionIndex = {
        tooltip: {
          trigger: 'axis'
        },
        visualMap: [
          {
            show: false,
            type: 'continuous',
            color: ['#b83840', '#ee3f4d', '#f89895', '#95caea', '#2c86e1'],
            min: Math.min(...this.values),
            max: Math.max(...this.values)
          }
        ],
        legend: {
          data: ['ESG指数']
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
            },
            data: this.time,
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: maxIndex,
            min: minIndex,
            interval: intervalIndex,
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: 'ESG指数',
            type: 'line',
            data: this.values,
            areaStyle: {
              color: "#309FEA",
              opacity: 0.06,
            },
            markLine: {
              symbol: 'none',
              silent: true,
              lineStyle: {
                color: '#0101f9',
                type: 'dotted'
              },
              data: [{
                yAxis: 1000
              }],
              label: {
                formatter: '1.0'
              },
            },
          }
        ]
      }
      this.chartIndex.setOption(optionIndex)
      // window.addEventListener('resize', function () {
      //   // chartIndex.resize()
      // })
    },

    drawMarketChart() {
      const chartMarket = echarts.init(this.$refs.chartsMarket)

      const optionMarket = {
        title: {
          text: '市场占比',
          x: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} <br/>({d}%)'
        },
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            dataView: {
              show: true,
              readOnly: false
            },
            restore: {show: true},
            saveAsImage: {show: true}
          }
        },
        series: [
          {
            name: '占比',
            type: 'pie',
            radius: ['0%', '40%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              formatter: '{d}%'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true
            },
            data: [
              {
                value: this.marketRatio.sz,
                name: '深圳证券交易所'
              },
              {
                value: this.marketRatio.sh,
                name: '上海证券交易所'
              }
            ]
          }
        ]
      }

      chartMarket.setOption(optionMarket)

      // window.addEventListener('resize', function () {
      //   // chartMarket.resize()
      // })
    },
    drawInIndustryChart() {
      const chartIndustry = echarts.init(this.$refs.chartsIndustry)

      const optionIndustry = {
        title: {
          text: '行业占比',
          x: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} <br/>({d}%)'
        },
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            dataView: {
              show: true,
              readOnly: false
            },
            restore: {show: true},
            saveAsImage: {show: true}
          }
        },
        series: [
          {
            name: '占比',
            type: 'pie',
            radius: ['0%', '40%'],
            avoidLabelOverlap: true,
            label: {
              show: true,
              formatter: '{b}'
            },
            itemStyle: {
              borderRadius: 4,
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true
            },
            data: this.industryRatio
          }
        ]
      }

      chartIndustry.setOption(optionIndustry)

      // window.addEventListener('resize', function () {
      //   // chartIndustry.resize()
      // })
    },
    drawer1open() {
      this.$refs.drawer1.visible = true
    },

    handleDrawerClose(done) {
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     this.$refs.drawer1.visible = false;
      //     done();
      //   })
      //   .catch(_ => {});
      this.$refs.drawer1.visible = false
      this.$refs.drawer2.visible = false
    },

    calcSize(that) {
      if (window.innerWidth < 900) {
        that.colWidths = [24, 24, 24, 24, 24, 24]
      } else {
        that.colWidths = [12, 12, 18, 6, 12, 12]
      }
    },
  },
  mounted() {
    this.getAllData()
    const that = this
    this.calcSize(this)
    window.onresize = () => {
      return (() => {
        that.calcSize(that)
      })()
    }
  },
  created() {
  }
}
</script>

<style>

.el-header {
  background-color: #34495E;
  color: #333;
  text-align: center;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

#chartIndex {
  width: 100%;
  height: 500px;
  margin: 0 auto;
  background-color: white;
}

#chartsMarket {
  width: 100%;
  height: 380px;
  margin: 0 auto;
  background-color: white;
}

#chartsIndustry {
  width: 100%;
  height: 380px;
  margin: 0 auto;
  background-color: white;
}

h1 {
  font-size: 2em;
  font-weight: normal;
}

h2 {
  font-size: 1.75rem;
  font-weight: normal;
}

h3 {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.8rem;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.3rem;
}

.descr {
  font-size: 1.1rem;
  margin: 0.8rem;
}

table th,
table td {
  border-bottom: none !important;
}

.el-table::before {
  /* 去除下边框 */
  height: 0;
}

.title {
  background-color: #E9EEF3;
  margin: 0;
  font-size: 25px;
  line-height: 1rem;
  text-align: left;
  display: inline-block;
  cursor: pointer;
}
</style>
