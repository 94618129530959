import axios from 'axios'
import store from '../store'

import {INDEX_MODULE} from '@/api/_prefix'

export const getIndex = () => {
  return axios.get(`${INDEX_MODULE}/get`,
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  })
}

export const getWeight = () => {
  return axios.get(`${INDEX_MODULE}/weight`,
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  })
}

export const getMarketRatio = () => {
  return axios.get(`${INDEX_MODULE}/marketRatio`,
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  })
}

export const getIndustryRatio = () => {
  return axios.get(`${INDEX_MODULE}/industryRatio`,
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  })
}
